@import '/src/styles/globals.scss';

.event {
  padding: 16px 24px;

  background-color: $greyBackgroundColor;
  border: 1px solid $dividerColor;
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
}

.leftSide {
  display: flex;
  align-items: flex-start;
  gap: 14px;
}

.idNumber {
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  color: $primaryBtnColor;

  width: 40px;
}

.divider {
  width: 1px;
  align-self: stretch;

  background-color: $dividerColor;
}

.mainInfo {
  margin-top: 4px;

  display: flex;
  flex-direction: column;
  gap: 6px;
}

.eventName {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.eventDate {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 400;
  p {
    color: $darkGreyColor;
  }

  span {
    color: $textColor;
  }
}

.dateDivider {
  width: 1px;
  height: 10px;

  background-color: $darkGreyColor;
}

.rightSide {
  height: fit-content;

  display: flex;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;
}

p {
  span {
    color: $darkGreyColor;
  }
}

.eventInfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.additionalInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  font-size: 14px;

  .info {
    display: flex;
    align-items: center;
    gap: 5px;

    margin-right: 26px;
  }

  .label {
    display: flex;
    align-items: center;
    gap: 10px;

    color: $placeholderTextColor;
  }
}
