@import '/src/styles/globals.scss';

.termWrapper {
  padding: 28px 26px;
  border: 1px solid $dividerColor;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.termHeader {
  display: flex;
  justify-content: space-between;
}

.date {
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-size: 16px;
    font-weight: 600;
  }
}

.rightHeader {
  display: flex;
  align-items: center;
  gap: 24px;

  p {
    font-size: 14px;
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }
}

.expandButton {
  width: 40px;
  height: 40px;

  p {
    display: flex;
    justify-content: center;
  }
}

.arrowUp {
  img {
    transform: rotate(180deg) !important;
  }
}

.respondents {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.respondent {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 22px;

  p {
    font-size: 14px;
    font-weight: 400;
    color: $placeholderTextColor;
  }
}

.respondentVoted {
  p {
    font-weight: 600;
    color: $textColor;
  }
}
