.userDropdown {
  position: relative;
}

.dropdownEnter {
  opacity: 0.01;
  transform: translate(0, -10px);
}

.dropdownEnterActive {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 0.2s ease-in;
}

.dropdownExit {
  opacity: 1;
  transform: translate(0, 0);
}

.dropdownExitActive {
  opacity: 0.01;
  transform: translate(0, -10px);
  transition: all 0.2s ease-out;
}
