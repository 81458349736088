@import '/src/styles/globals.scss';

.modalBackground {
  padding: 40px 20px 20px;

  position: fixed;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
}

.modal {
  width: 610px;
  padding: 29px 29px 45px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
