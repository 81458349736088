@import '/src/styles/globals.scss';

.checkbox {
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  appearance: none;

  background: $white-color;
  border: 1px solid $inputBorderColor;
  border-radius: 6px;

  cursor: pointer;
  outline-color: $primaryBtnColor;

  &:after {
    content: '';
    display: block;
    transform: scale(0);
    transition: 0.12s transform ease-in-out;
  }

  &:checked:after {
    transform: scale(1);
  }

  &:after {
    width: 16px;
    height: 16px;
  }

  &:checked {
    border: none;
    background-color: $primaryBtnColor;

    &:after {
      content: url("data:image/svg+xml, <svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill='white' d='M15.25.847a1.51 1.51 0 0 1 .405 2.096L8.106 14.11a1.944 1.944 0 0 1-2.94.329L.6 10.156a1.51 1.51 0 1 1 2.067-2.202l3.645 3.42 6.841-10.122a1.51 1.51 0 0 1 2.098-.405Z'/></svg>");
    }
  }
}
