@import '/src/styles/globals.scss';

.nav {
  width: fit-content;
  height: 100%;

  margin: 0;

  display: flex;
  align-items: center;
  gap: 35px;

  list-style-type: none;

  overflow: hidden;
}

.link {
  position: relative;

  font-size: 16px;
  font-weight: 600;
  height: 100%;

  display: flex;
  align-items: center;

  text-decoration: none;

  &::after {
    display: block;
    position: absolute;
    bottom: -2px;
    content: '';
    width: 0;
    height: 4px;
    background-color: $primaryBtnColor;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.2s linear;
  }

  &:hover::after {
    width: 40px;
  }

  &:focus {
    outline: none;
    border: 3px solid $outlineColor;
  }
}

.activeTab {
  &::after {
    display: block;
    position: absolute;
    bottom: -2px;
    content: '';
    width: 40px;
    height: 4px;
    background-color: #8444e3;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
}
