@import '/src/styles/globals.scss';

.formInput {
  width: 345px;

  display: flex;
  flex-direction: column;
  gap: 6px;

  label {
    font-weight: 400;
    font-size: 14px;
  }

  p {
    font-size: 12px;
    font-weight: 400;

    color: $red-color;
  }
}

.select {
  padding: 12px;

  border: 1px solid $inputBorderColor;
  border-radius: 6px;

  font-size: 14px;
  font-weight: 400;
  color: $placeholderTextColor;

  option {
    font-size: 14px;
    font-weight: 400;
    color: $placeholderTextColor;
  }

  &:focus {
    border: 1px solid $primaryBtnColor;
    outline: none;
  }
}
