@import '/src/styles/globals.scss';

.pollSummary {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.groupInfo {
  display: flex;
  flex-direction: column;
  gap: 18px;

  .label {
    display: flex;
    align-items: center;
    gap: 14px;

    p {
      font-size: 18px;
      font-weight: 600;
    }

    img {
      width: 16px;
    }
  }

  .info {
    margin-left: 29px;

    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.groupInfoDuration {
  @extend .groupInfo;

  gap: 6px;
}

.groupInfoProposedDates {
  @extend .groupInfo;
  gap: 7px;
}

.timesButton {
  width: fit-content;

  padding: 5px 10px;
  margin-left: 19px;

  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover {
    text-decoration: underline;
  }

  img {
    transition: transform 0.3s linear;
  }
}

.timesButtonActive {
  img {
    transform: rotate(180deg) !important;
  }
}

.timesContainer {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.datetimesContainer {
  margin-left: 29px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .date {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  .dateTitle {
    font-size: 12px;
    font-weight: 600;
  }

  .times {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    li {
      width: fit-content;

      font-size: 14px;
      font-weight: 600;

      padding: 8px 12px;

      background-color: $greyBackgroundColor;
      border: 1px solid $inputBorderColor;
      border-radius: 6px;
    }
  }
}
