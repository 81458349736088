@import '/src/styles/globals.scss';

.chooseCard {
  width: 100%;

  padding: 42px 85px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;

  h1 {
    font-size: 32px;
    font-weight: 600;
  }
}

.cardTitle {
  grid-area: cardHeader;
  padding: 42px 80px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 1px solid $dividerColor;
}

.emailEdition {
  display: grid;
  grid-template-columns: 420px calc(100% - 420px);
  grid-template-areas: 'aside main';

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.aside {
  grid-area: aside;

  padding: 47px 45px 44px 80px;

  border-right: 1px solid $dividerColor;

  display: flex;
  flex-direction: column;
  gap: 14px;

  p {
    font-size: 14px;
    font-weight: 600;
    // color: $placeholderTextColor;
  }

  .additionalParagraph {
    margin-top: 12px;
  }

  .infoParagraph {
    margin-top: 7px;
    font-weight: 400;
    color: $placeholderTextColor;
  }

  .itemsList {
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 5px;

    .item {
      padding: 5px 14px;
      background-color: $grayColor;
      border: 1px solid $lightPurpleBackgroundColor;
      border-radius: 6px;
      cursor: pointer;

      width: 280px;

      &:hover {
        border-color: $primaryBtnColor;
      }
    }

    .activeItem {
      border-color: $primaryBtnColor;
      display: flex;
      justify-content: space-between;
    }
  }
}

.main {
  grid-area: main;

  form {
    padding: 47px 45px 44px 80px;

    display: flex;
    flex-direction: column;
    gap: 34px;
  }

  .sectionHeader {
    display: flex;
    align-items: center;
    gap: 18px;

    h3 {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .punction {
    width: 28px;
    height: 28px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $lightPurpleBackgroundColor;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border: 1px solid $darkPurpleBorderColor;
    border-radius: 6px;

    font-size: 14px;
    font-weight: 600;
    color: $primaryBtnColor;
  }
}

.titleEditionSection {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.additionalTitleInfo {
  margin-left: 46px;

  font-size: 12px;
  font-weight: 400;
  color: $placeholderTextColor;
}

.disabledRadio {
  color: $placeholderTextColor;
  cursor: auto !important;

  .radioInput {
    cursor: auto !important;
  }
}

.radioSendingEmails {
  margin-left: 46px;

  display: flex;
  gap: 24px;

  label {
    font-size: 14px;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .radioInput {
    cursor: pointer;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    border: 1px solid $placeholderTextColor;
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &:checked {
      border: 7px solid $primaryBtnColor;
    }
  }
}

.inputContainer {
  margin-left: 46px;
  padding: 16px 16px;

  background-color: $grayColor;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    font-size: 14px;
    font-weight: 400;
  }

  input {
    height: 40px;
    padding: 12px;

    font-size: 14px;

    border: 1px solid $inputBorderColor;
    border-radius: 6px;

    transition: 0.3s;

    &:focus {
      border: 1px solid $primaryBtnColor;
      outline: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $placeholderTextColor;
    }
  }
}

.templateEditionSection {
  display: flex;
  flex-direction: column;
  gap: 18px;

  height: auto;

  .textEditor {
    margin-left: 46px;
    padding: 16px 16px;

    background-color: $grayColor;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.gridSections {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;

  section {
    width: calc(50% - 25px);
  }
}

.testingEmail {
  display: flex;
  flex-direction: column;
  gap: 18px;

  .inputContainer {
    flex-direction: row;
    align-items: center;
    gap: 30px;

    input {
      width: 100%;
    }

    button {
      width: 150px;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 26px;
}

.saveBtn {
  width: 175px;
}

.testBtn {
  width: 210px;
}

.emailPreview {
  display: flex;
  flex-direction: column;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;

  .emailPreviewCard {
    margin: 24px auto;
    padding: 24px 54px;
    width: 650px;

    background: $white-color;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
    // border: 1px solid $inputBorderColor;
    border-radius: 25px;

    .branding {
      justify-content: flex-start;

      svg {
        width: 37px;
        height: 37px;
      }

      p {
        font-size: 16px;
      }
    }

    .divider {
      height: 1px;
      width: 100%;

      background-color: $dividerColor;

      margin: 24px 0;
    }

    .emailContent {
      min-height: 100px;

      margin-bottom: 34px;

      h1 {
        font-size: 24px;
        font-weight: 600;
      }

      p {
        font-size: 18px;
        font-weight: 400;
      }

      a {
        text-decoration: underline;
        color: #8444e3;
      }

      ul {
        font-size: 18px;
        font-weight: 400;
      }

      h5 {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
      }
    }

    .emailFooter {
      display: flex;
      justify-content: space-between;

      padding-top: 14px;
      border-top: 1px solid $dividerColor;

      p {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

.emailEditionCardTitle {
  grid-area: cardHeader;
  padding: 42px 80px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  border-bottom: 1px solid $dividerColor;

  div {
    width: 500px;
    font-size: 14px;

    p {
      font-weight: 600;

      span {
        font-weight: 400;
      }
    }
  }
}
