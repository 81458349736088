@import '/src/styles/globals.scss';

.userDropdown {
  width: 100%;

  position: absolute;
  bottom: -100px;
  z-index: 999;

  border-radius: 6px;
  background-color: $white-color;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);

  padding: 3px 0;

  p {
    font-size: 14px;
    font-weight: 600;

    margin: 4px 0;
    padding: 7px 15px;

    display: flex;
    align-items: center;
    gap: 14px;

    cursor: pointer;

    &:hover {
      background-color: $grayColor;
    }

    img {
      object-fit: none;
      width: 18px;
    }
  }

  .disabled {
    color: $placeholderTextColor;
    cursor: auto;

    &:hover {
      background-color: $white-color;
    }
  }
}
