@import '/src/styles/globals.scss';

.votingContainer {
  padding: 0 50px 34px 45px;

  display: flex;
  flex-direction: column;
  gap: 22px;

  @media screen and (max-width: 700px) {
    padding: 0 20px 34px 20px;
  }
}

.daysContainer {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.dayContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 700px) {
    gap: 15px;
  }
}

.dayHeader {
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-size: 14px;
    font-weight: 400;
  }
}

.datesRow {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  @media screen and (max-width: 700px) {
    gap: 15px;
  }
}

.checkbox {
  margin: 0;
}

.dateButton {
  width: 170px;

  padding: 10px 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 12px;
  font-weight: 600;
  color: $primaryBtnColor;
  text-align: left;

  background-color: $lightPurple;
  border: 1px solid $primaryBtnColor;
  border-radius: 6px;

  cursor: pointer;

  span {
    font-size: 12px;
    font-weight: 600;
    color: $primaryBtnColor;

    display: flex;
    align-items: center;
  }

  img {
    margin-right: 10px;
    width: 16px;
  }
}

.activeDateButton {
  background-color: $primaryBtnColor;
  color: $white-color;

  span {
    color: $white-color;
  }
}

.footerChecks {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.votingButton {
  width: 100%;

  padding: 14px;

  font-size: 14px;
  font-weight: 400;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  border: 1px solid $inputBorderColor;
  border-radius: 6px;
  background-color: $white-color;

  cursor: pointer;
  transition: border-color 0.2s linear;

  p {
    text-align: left;
  }

  &:hover {
    border-color: $btnHoverColor;
    text-decoration: underline;
  }

  &:focus {
    outline: 3px solid $outlineColor;
  }
}

.activeVotingButton {
  background-color: $grayColor;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.nonSuitesButton {
  border-radius: 6px 6px 0 0;
  background-color: $grayColor;
}

.noneSuitsCommentContainer {
  width: 100%;

  padding: 36px 35px 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border: 1px solid $inputBorderColor;
  border-top: none;
  border-radius: 0 0 6px 6px;
  background-color: $grayColor;

  @media screen and (max-width: 1000px) {
    padding: 36px 20px 24px;
  }

  .textarea {
    width: 100%;

    textarea {
      width: 100%;
    }

    p {
      font-size: 12px;
      color: $placeholderTextColor;
      text-align: right;
    }

    .charactersLimit {
      color: $red-color;
    }
  }

  textarea {
    resize: none;
    height: 120px;

    padding: 12px;

    font-size: 14px;

    border: 1px solid $inputBorderColor;
    border-radius: 6px;

    @media screen and (max-width: 1000px) {
      height: 240px;
      font-size: 16px;
    }

    &:focus {
      border: 1px solid $primaryBtnColor;
      outline: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $placeholderTextColor;
    }
  }
}

.nonSuitsCommentLabel {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-size: 18px;
    font-weight: 600;
  }
}

.confirmAnywayContainer {
  padding: 20px 16px 20px 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  background-color: $lightPurple;
  border-radius: 6px;

  p {
    width: 320px;
    font-size: 12px;
    font-weight: 400;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;

    padding: 20px;

    p {
      width: 100%;
      text-align: center;
    }
  }
}

.confirmAnywayButton {
  width: 215px;

  p {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }
}

.confirmContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;

  p {
    font-size: 14px;
    font-weight: 400;
  }
}

.confirmButton {
  width: 175px;

  p {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
  }
}
