@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }
}

.pageCard {
  display: grid;
  grid-template-columns: 33% 67%;
  grid-template-areas:
    'backHeader backHeader'
    'aside editionForm';

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.backHeader {
  grid-area: backHeader;
}

.requisitionInfo {
  grid-area: aside;

  padding: 43px 45px 43px 80px;

  border-right: 1px solid $dividerColor;

  display: flex;
  flex-direction: column;
  gap: 17px;
}

.editionForm {
  grid-area: editionForm;

  padding: 43px 100px 43px 45px;
}
