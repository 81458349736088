@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }
}

.pageCard {
  width: 100%;
  height: 750px;

  padding: 24px 43px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.header {
  display: flex;
  justify-content: center;

  margin-bottom: 24px;
}

.brandingLogo {
  p {
    font-size: 18px;
  }

  svg {
    width: 37px !important;
    height: 37px !important;
  }
}

.divider {
  width: 100%;
  height: 1px;

  background-color: $dividerColor;
}

.informationContainer {
  width: 100%;
  min-height: calc(100% - 70px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.information {
  width: 1000px;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 90px;
  height: 90px;

  margin: 15px 0 26px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  border: 1px solid $darkPurpleBorderColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 100px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  h1 {
    font-size: 24px;
    font-weight: 600;
  }
}

.linkForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.input {
  width: 100%;
}

.addLink {
  width: 565px;

  padding: 36px 70px;

  display: flex;
  flex-direction: column;
  gap: 26px;

  background-color: $grayColor;
  border-radius: 20px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;

    margin-bottom: 4px;
  }

  p {
    font-size: 16px;
    font-weight: 600;

    display: flex;
    align-items: center;
    gap: 8px;

    span {
      font-size: 14px;
      font-weight: 400;
      color: $placeholderTextColor;

      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 16px;
      }
    }
  }
}

.meetingInfo {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.button {
  width: 210px;
}
