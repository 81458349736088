@import '/src/styles/globals.scss';

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 90px;
  height: 90px;

  margin: 15px 0 17px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  border: 1px solid $darkPurpleBorderColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 100px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  h2 {
    font-size: 24px;
    font-weight: 600;
  }
}

.input {
  width: 400px;

  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-size: 12px;
    color: $placeholderTextColor;
    width: 100%;
    text-align: right;
  }

  .charactersLimit {
    color: $red-color;
  }

  label {
    font-size: 14px;
    font-weight: 400;
  }

  textarea {
    resize: none;
    height: 80px;

    padding: 12px;

    font-size: 14px;

    border: 1px solid $inputBorderColor;
    border-radius: 6px;

    &:focus {
      border: 1px solid $primaryBtnColor;
      outline: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $placeholderTextColor;
    }
  }
}

.actionButtons {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  width: 230px;
  height: 40px;
}

.cancelButton {
  width: 155px;
  height: 40px;
}
