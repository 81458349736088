@import '/src/styles/globals.scss';

.search {
  position: relative;

  img {
    position: absolute;
    top: 0;
    right: 10px;

    top: 50%; /* position the top  edge of the element at the middle of the parent */

    transform: translateY(-50%);
  }
}

.searchInput {
  width: 240px;
  height: 40px;

  padding: 12px;
  padding-right: 40px;

  border: 1px solid $inputBorderColor;
  border-radius: 6px;

  transition: 0.3s;

  &:focus {
    border: 1px solid $primaryBtnColor;
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: $placeholderTextColor;
  }
}
