@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }
}

.calendarCard {
  display: flex;
  flex-direction: column;
  gap: 36px;

  padding-bottom: 36px;

  background: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.backButton {
  width: 115px;
}

.proposeDatesButton {
  width: 175px;
}
