@import '/src/styles/globals.scss';

.primaryButton {
  padding: 12px 0;

  background-color: $primaryBtnColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 6px;

  transition: background-color 0.2s linear;

  p {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;

    color: $white-color;

    img {
      transition: transform 0.3s linear;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $btnHoverColor;
  }

  &:focus {
    outline: 3px solid $outlineColor;
  }
}

.secondaryButton {
  background-color: $white-color;
  border: 1px solid $inputBorderColor;
  border-radius: 6px;

  transition: border-color 0.2s linear;

  p {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;

    img {
      transition: transform 0.3s linear;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: $btnHoverColor;
  }

  &:focus {
    outline: 3px solid $outlineColor;
  }
}

.disabledPrimary {
  background-color: $disabledPurpleColor;

  &:hover {
    cursor: auto;
    background-color: $disabledPurpleColor;
  }

  &:focus {
    outline: none;
  }
}

.disabledSecondary {
  border-color: $disabledGrayColor;

  p {
    color: $disabledGrayColor;
  }

  &:hover {
    cursor: auto;
    border-color: $disabledGrayColor;

    p {
      color: $disabledGrayColor;
    }
  }

  &:focus {
    outline: none;
  }
}
