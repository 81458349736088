@import '/src/styles/globals.scss';

.candidatesDetails {
  width: 100%;

  margin: 16px 0 20px;

  display: flex;
  flex-direction: column;
}

.header {
  padding: 0 85px;

  ul {
    padding: 0;
    padding-bottom: 16px;

    display: grid;
    grid-template-columns: 0.4fr 1.4fr 0.8fr 1.8fr 1fr 40px;
    align-items: center;

    li {
      font-size: 14px;
      font-weight: 400;
      color: $placeholderTextColor;
    }
  }
}

.candidatesList {
  padding: 0;

  .candidateListItem {
    padding: 16px 85px;
    border-top: 1px solid $dividerColor;
  }

  .candidateListItem:nth-child(even) {
    background-color: $greyBackgroundColor;
  }

  .candidateListItem:last-child {
    border-bottom: 1px solid $dividerColor;
  }
}

.newPollButton {
  width: 250px;

  margin: 35px auto 0 auto;
}
