@import '/src/styles/globals.scss';

.form {
  margin-left: 46px;

  display: flex;
  flex-direction: column;
  gap: 35px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.longSelectInput {
  width: 530px;
}

.shortSelectInput {
  width: 150px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.backButton {
  width: 115px;
}

.proposeDatesButton {
  width: 175px;
}

.meetingDetailsSectionContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.meetingDetailsSectionHeader {
  display: flex;
  align-items: center;
  gap: 18px;

  h3 {
    font-size: 18px;
    font-weight: 600;
  }
}

.punction {
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid $darkPurpleBorderColor;
  border-radius: 6px;

  font-size: 14px;
  font-weight: 600;
  color: $primaryBtnColor;
}
