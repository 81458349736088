@import '/src/styles/globals.scss';

.candidateName {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    font-weight: 600;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color: $placeholderTextColor;
  }
}
