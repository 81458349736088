@import '/src/styles/globals.scss';

.alerts {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  z-index: 9999;
}

.alert {
  width: 100%;
  background-color: #282828;
  padding: 14px 0;

  display: flex;
  justify-content: center;
}

.alertContent {
  display: flex;
  justify-content: space-between;

  width: 1145px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 14px;

  p {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
}

.succesIcon {
  width: 42px;
  height: 42px;

  background-color: #83b400;
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 13px;
    height: 10px;
  }
}

.errorIcon {
  width: 42px;
  height: 42px;

  background-color: #ff3607;
  border-radius: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 13px;
  }
}

.closeAlertButton {
  width: 32px;
  height: 32px;

  background-color: #282828;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid #8a8a8a;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 3px solid $outlineColor;
  }
}
