@import '/src/styles/globals.scss';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading {
  margin: 20px auto;
  width: 50px;
  height: 50px;
  border: 8px solid $lightPurpleBackgroundColor; /* Light grey */
  border-top: 8px solid $primaryBtnColor; /* Black */
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}
