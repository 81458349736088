@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }
}

.createMeetingCard {
  display: grid;
  grid-template-columns: 33% 67%;
  grid-template-areas:
    'Hheader Hheader'
    'aside createPollForm';

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.header {
  grid-area: Hheader;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid $dividerColor;
  padding-right: 85px;

  p {
    font-size: 14px;
    display: flex;
    gap: 8px;

    span {
      color: $placeholderTextColor;
    }
  }
}

.backHeader {
  width: fit-content;
  border: none;
}

.requisitionInfo {
  grid-area: aside;

  padding: 43px 45px 43px 80px;

  border-right: 1px solid $dividerColor;
}

.statusBean {
  margin-bottom: 12px;
}

.pollDetails {
  grid-area: createPollForm;

  padding: 43px 100px 43px 45px;
}

.divider {
  width: 100%;
  height: 1px;

  margin: 28px 0;

  background-color: $dividerColor;
}

.primaryButton {
  width: 100%;
}
