@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }
}

.card {
  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.content {
  margin: 0;
  padding: 0 85px 56px;

  display: flex;
  flex-direction: column;
  gap: 6px;
}

.info {
  padding: 36px 85px 32px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.requisitionDetailsList {
  padding: 0;
  margin: 0;

  list-style: none;

  display: flex;
  gap: 40px;

  li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 9px;

    overflow-wrap: break-word;

    font-size: 14px;
    font-weight: 400;

    img {
      width: 20px;
      object-fit: none;
    }
  }
}

.label {
  color: $placeholderTextColor;
}
