@import '/src/styles/globals.scss';

.form {
  margin-left: 46px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 16px 16px;

  background-color: $grayColor;
  border-radius: 10px;
}

.inputs {
  display: flex;
  gap: 12px;
}

.shortInput {
  width: 25% !important;
}

.longInput {
  width: 45% !important;
}

.button {
  width: fit-content;

  display: flex;
  align-items: center;
  gap: 12px;

  border: none;
  background-color: inherit;

  p {
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;

    p {
      text-decoration: underline;
    }

    .plus {
      background-color: $btnHoverColor;
    }
  }

  &:focus {
    outline: 3px solid $outlineColor;
  }
}

.plus {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $primaryBtnColor;
  border-radius: 100px;
  transition: background-color 0.2s linear;
}
