@import '/src/styles/globals.scss';

.modal {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  h3 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 400;

    width: 320px;
    text-align: center;
  }
}

.termsList {
  width: 100%;
  max-height: 340px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primaryBtnColor;
    outline: 1px solid $primaryBtnColor;
    border-radius: 4px;
  }
}

.term {
  padding: 14px 26px;
  border: 1px solid $dividerColor;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  cursor: pointer;

  &:hover {
    border-color: $primaryBtnColor;
  }
}

.chosenTerm {
  border-color: $primaryBtnColor;
}

.termHeader {
  display: flex;
  justify-content: space-between;
}

.date {
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    font-weight: 600;
  }
}

.rightHeader {
  display: flex;
  align-items: center;
  gap: 24px;

  p {
    font-size: 14px;
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.primaryButton {
  width: 230px;
  height: 40px;
}

.cancelButton {
  width: 150px;
  height: 40px;
}
