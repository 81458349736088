@import '/src/styles/globals.scss';

.requisitionInfo {
  h3 {
    font-size: 22px;
    font-weight: 600;

    overflow-wrap: break-word;
  }
}

.personShortenInfo {
  p {
    font-size: 16px;
  }

  span {
    font-size: 14px;
  }
}

.requisitionDetailsList {
  padding: 0;
  margin: 20px 0 0;

  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 9px;

    overflow-wrap: break-word;

    font-size: 14px;
    font-weight: 400;

    img {
      width: 20px;
      object-fit: none;
    }
  }

  .contactPersonListItem {
    flex-direction: column;
    align-items: flex-start;

    .contactPersonLabel {
      display: flex;
      align-items: center;
      gap: 9px;
    }

    .contactPerson {
      padding-left: 29px;
    }
  }
}

.label {
  color: $placeholderTextColor;
}
