@import '/src/styles/globals.scss';

.ql-editor {
  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    font-weight: 400;
  }

  a {
    text-decoration: underline;
    color: #8444e3;
  }

  ul {
    font-size: 18px;
    font-weight: 400;
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
  }
}

.ql-Button {
  width: fit-content;
  font-weight: 600;
  font-size: 14px;

  &:hover {
    color: $primaryBtnColor !important;
  }
}

.ql-picker-label:hover {
  color: $primaryBtnColor !important;

  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
}

.ql-picker-item:hover {
  color: $primaryBtnColor !important;
}

.ql-selected {
  color: $primaryBtnColor !important;
}

.ql-bold:hover {
  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
  .ql-fill {
    fill: $primaryBtnColor !important;
  }
}

.ql-italic:hover {
  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
  .ql-fill {
    fill: $primaryBtnColor !important;
  }
}
.ql-underline:hover {
  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
  .ql-fill {
    fill: $primaryBtnColor !important;
  }
}
.ql-strike:hover {
  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
  .ql-fill {
    fill: $primaryBtnColor !important;
  }
}
.ql-list:hover {
  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
  .ql-fill {
    fill: $primaryBtnColor !important;
  }
}
.ql-blockquote:hover {
  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
  .ql-fill {
    fill: $primaryBtnColor !important;
  }
}
.ql-align:hover {
  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
  .ql-fill {
    fill: $primaryBtnColor !important;
  }
}
.ql-color:hover {
  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
  .ql-fill {
    fill: $primaryBtnColor !important;
  }
}
.ql-link:hover {
  .ql-stroke {
    stroke: $primaryBtnColor !important;
  }
  .ql-fill {
    fill: $primaryBtnColor !important;
  }
}
