@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }
}

.mainCard {
  width: 100%;

  padding: 42px 85px 0;

  display: flex;
  flex-direction: column;
  gap: 40px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;

  h1 {
    font-size: 32px;
    font-weight: 600;
  }
}

.options {
  list-style: none;
  overflow: hidden;

  margin: 0;
  padding: 0;

  display: flex;
  gap: 36px;

  font-size: 14px;
  font-weight: 400;

  li {
    height: 100%;

    position: relative;

    font-size: 14px;
    font-weight: 400;

    padding: 0 7px 14px;
    cursor: pointer;

    &::after {
      display: block;
      position: absolute;
      bottom: -2px;
      content: '';
      width: 0;
      height: 4px;
      background-color: $primaryBtnColor;
      border-radius: 4px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.2s linear;
    }

    &:hover::after {
      width: 100%;
    }
  }

  .activeOption {
    font-weight: 600;

    &::after {
      display: block;
      position: absolute;
      bottom: -2px;
      content: '';
      width: 100%;
      height: 4px;
      background-color: $primaryBtnColor;
      border-radius: 4px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.2s linear;
    }
  }
}
