@import '/src/styles/globals.scss';

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 37px;
}

.modalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  width: 310px;
  text-align: center;

  h3 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    span {
      font-weight: 600;
    }
  }
}

.thumbnail {
  position: relative;

  height: 96px;
  width: 96px;
}

.initials {
  width: 89px;
  height: 89px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 24px;
  font-weight: 600;
  color: $white-color;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  background-color: $blue-color;
  border-radius: 100px;
}

.icon {
  position: absolute;
  right: 0;
  bottom: 0;

  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid $darkPurpleBorderColor;
  border-radius: 100px;

  img {
    width: 14px;
    height: 14px;
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.addButton {
  width: 170px;
  height: 40px;
}

.cancelButton {
  width: 115px;
  height: 40px;
}
