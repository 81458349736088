@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }
}

.pageCard {
  display: grid;
  grid-template-columns: 33% 67%;
  grid-template-areas:
    'backHeader backHeader'
    'aside editionForm';

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.backHeader {
  grid-area: backHeader;
}

.requisitionInfo {
  grid-area: aside;

  padding: 43px 45px 43px 80px;

  border-right: 1px solid $dividerColor;

  display: flex;
  flex-direction: column;
  gap: 17px;
}

.details {
  grid-area: editionForm;

  padding: 43px 100px 43px 45px;
}

.meetingDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 37px;
}

.label {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;

  display: flex;
  align-items: center;
  gap: 12px;

  img {
    width: 16px;
  }
}

.resignationReason {
  display: flex;
  flex-direction: column;
  gap: 18px;

  p {
    margin-left: 29px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}

.candidate {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.candidateTile {
  margin-left: 29px;
  padding: 16px;

  display: flex;
  align-items: center;
  gap: 8px;

  background-color: $grayColor;
  border-radius: 10px;
}

.initials {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $pendingColor;
  border-radius: 100px;

  font-size: 12px;
  font-weight: 600;
  color: $white-color;
}

.candidateDetails {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    color: $placeholderTextColor;
  }
}

.participants {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.participantsList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.participantListItem {
  margin-left: 29px;
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.confirmButton {
  width: 175px;
  height: 40px;
}

.skipButton {
  width: 115px;
  height: 40px;
}
