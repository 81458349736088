@import '/src/styles/globals.scss';

.participant {
  margin-left: 46px;
  padding: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $grayColor;
  border-radius: 10px;
}

.participantInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.initials {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $pendingColor;
  border-radius: 100px;

  font-size: 12px;
  font-weight: 600;
  color: $white-color;
}

.participantDetails {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    color: $placeholderTextColor;
  }
}

.participantRemove {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
}
