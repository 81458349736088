@import '/src/styles/globals.scss';

.pagination {
  width: 100%;
  padding: 24px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.paginationItem {
  width: 32px;
  height: 32px;

  font-size: 14px;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid $inputBorderColor;
  border-radius: 100px;

  transition: border-color 0.2s linear;

  &:hover {
    cursor: pointer;
    border-color: $primaryBtnColor;
  }
}

.active {
  background-color: $primaryBtnColor;
  color: $white-color;

  &:hover {
    cursor: default;
  }
}

.leftArrow {
  @extend .paginationItem;

  img {
    transform: rotateZ(90deg);
  }
}

.rightArrow {
  @extend .paginationItem;

  img {
    transform: rotateZ(-90deg);
  }
}
