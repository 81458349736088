@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }

  @media screen and (max-width: 1180px) {
    width: calc(100% - 16px);

    margin: 40px 8px;
  }

  @media screen and (max-width: 900px) {
    width: 700px;

    margin: 40px auto;
  }

  @media screen and (max-width: 720px) {
    width: calc(100% - 8px);

    margin: 40px 4px;
  }
}

.pageCard {
  position: relative;
  width: 100%;
  height: 800px;

  padding: 24px 43px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;

  @media screen and (max-width: 900px) {
    padding: 24px 8px;
    height: 900px;
  }
}

.header {
  display: flex;
  justify-content: center;

  margin-bottom: 24px;
}

.brandingLogo {
  p {
    font-size: 18px;
  }

  svg {
    width: 37px !important;
    height: 37px !important;
  }
}

.divider {
  width: 100%;
  height: 1px;

  background-color: $dividerColor;
}

.informationContainer {
  width: 100%;
  min-height: calc(100% - 70px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.information {
  width: 100%;
  max-width: 1000px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px) {
    height: 700px;
  }
}

.icon {
  width: 90px;
  height: 90px;

  margin: 15px 0 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  border: 1px solid $darkPurpleBorderColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 100px;
}

.confirmContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    max-width: 440px;

    font-size: 14px;
    font-weight: 400;
    color: $placeholderTextColor;

    text-align: center;
  }
}

.infoWrapper {
  width: 560px;
  padding: 32px 67px;

  background-color: $grayColor;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h3 {
    font-size: 20px;
    font-weight: 600;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.companyName {
  font-size: 14px;
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 16px;
  }
}

.footer {
  position: absolute;
  bottom: 26px;

  font-size: 16px;
  font-weight: 400;
  text-align: center;

  padding: 0 8px;

  a {
    color: $primaryBtnColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.question {
  font-size: 20px;
  font-weight: 600;

  text-align: center;
}

.confirmButton {
  width: 170px;
}

.formContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  p {
    font-size: 14px;
  }
}

.formWrapper {
  width: 700px;
  padding: 32px 67px;

  background-color: $grayColor;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;

  @media screen and (max-width: 900px) {
    width: calc(100% - 16px);
    padding: 32px 8px;
    align-items: center;

    margin: 0 8px;

    div {
      width: 100%;
    }
  }

  .textarea {
    p {
      font-size: 12px;
      color: $placeholderTextColor;
      text-align: right;
    }

    .charactersLimit {
      color: $red-color;
    }
  }

  textarea {
    resize: none;
    height: 80px;
    width: 560px;

    margin-top: 6px;
    padding: 12px;

    font-size: 14px;

    border: 1px solid $inputBorderColor;
    border-radius: 6px;

    @media screen and (max-width: 900px) {
      width: 100%;
      height: 240px;
      font-size: 16px;
    }

    &:focus {
      border: 1px solid $primaryBtnColor;
      outline: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $placeholderTextColor;
    }
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 15px;

  margin-top: 15px;
}

.confirmButton {
  width: 170px;
  height: 40px;
}

.skipButton {
  width: 115px;
  height: 40px;
}
