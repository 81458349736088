@import '/src/styles/globals.scss';

.header {
  width: 100%;
  height: 100px;

  padding: 0 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 25px;
  background-color: $white-color;
  box-shadow: 0 20px 40px rgb(0 0 0 / 4%);
}
