@import '/src/styles/globals.scss';

.loginContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loginCard {
  width: 536px;
  // height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px 0 70px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);

  border-radius: 25px;
}

.branding {
  width: 100%;
  padding-bottom: 32px;
  border-bottom: 1px solid $dividerColor;

  p {
    font-size: 18px !important;
    margin-left: 7px !important;
  }

  svg {
    width: 37px !important;
    height: 37px !important;
  }
}

.header {
  font-weight: 600;
  font-size: 36px;
}

.ADButton {
  width: 247px;
  height: 40px;
}

.content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 17px auto 26px;
    font-size: 24px;
    font-weight: 600;
  }
}

.autoSignedOut {
  width: 100%;

  padding: 22px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  background-color: $grayColor;

  p {
    font-size: 16px;
    font-weight: 600;
  }
}

.signOutIcon {
  width: 41px;
  height: 41px;
  background-color: $lightPurpleBackgroundColor;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid $darkPurpleBorderColor;
  border-radius: 50%;
}
