.AIMatchingRequisitionsList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}
