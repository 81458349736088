@import './variables.scss';
@import './reset.scss';

* {
  color: $textColor;
}

html {
  background-color: $applicationBackground;
}

body {
  font-family: 'TT Hoves', sans-serif;
}
