@import '/src/styles/globals.scss';

.pollStatus {
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-size: 12px;
    font-weight: 400;
    color: $infoColor;
  }

  .pollStatusText {
    font-size: 14px;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.dot {
  width: 8px;
  height: 8px;

  border-radius: 100px;
}

.pending {
  background-color: $pendingColor;
}
.sentToClient {
  background-color: $sentToClientColor;
}

.forVerification {
  background-color: $red-color;
}

.expired {
  background-color: $expiredColor;
}

.default {
  background-color: $defaultColor;
}
