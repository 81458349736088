$applicationBackground: #ececec;
$white-color: #ffffff;
$red-color: #ff3607;
$orange-color: #ff7e08;
$green-color: #00c29b;
$blue-color: #00b5ff;
$greyBackgroundColor: #fbfbfb;

$textColor: #474747;
$placeholderTextColor: #959595;
$primaryBtnColor: #8444e3;
$inputBorderColor: #d7d7d7;
$btnHoverColor: #742edd;

$dividerColor: #e8e8e8;
$outlineColor: #e6d6ff;

$pendingColor: #194eef;
$sentToClientColor: #00b5ff;
$expiredColor: #858282;
$defaultColor: #cdcdcd;

$infoColor: #a2a2a2;
$grayColor: #f9f9f9;
$darkGreyColor: #959595;

$darkPurpleBorderColor: #ad79fa;
$lightPurpleBackgroundColor: #decef5;

$disabledPurpleColor: #dac7f7;
$disabledGrayColor: #ededed;
$lightPurple: #faf5ff;
