@import '/src/styles/globals.scss';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD

.rbc-today {
  background-color: transparent;
}
.rbc-toolbar,
.rbc-time-header.rbc-overflowing {
  display: none;
}

.rbc-time-content::-webkit-scrollbar {
  display: none;
}

.rbc-time-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: none;
}

.rbc-time-gutter {
  width: 100px;
  padding: 0 10px;
  margin-top: -14px;

  .rbc-time-slot {
    .rbc-label {
      font-size: 14px;
      font-weight: 400;
      color: $placeholderTextColor;
    }
  }
}

.rbc-timeslot-group {
  border-bottom: none;
  border-color: $disabledGrayColor;
}

.rbc-time-slot,
.rbc-day-slot {
  border-color: $disabledGrayColor;
}
.rbc-current-time-indicator {
  display: none;
}

.rbc-addons-dnd-resize-ns-icon {
  // display: none !important;
  opacity: 0;
}

.rbc-allday-cell {
  display: none;
}

.rbc-timeslot-group {
  min-height: 112px;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  max-height: 157px;

  margin: 6px;
  padding: 13px 16px;

  background: $lightPurple;
  border: 1px solid $primaryBtnColor;
  border-radius: 6px;
}

.rbc-event-label {
  font-size: 12px;
  font-weight: 600;
  color: $primaryBtnColor;
}

.rbc-event-content {
  font-size: 12px;
  font-weight: 600;
  color: $red-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-slot-selection {
  display: none;
}
.rbc-slot-selecting {
  cursor: default;
}

.rbc-event {
  z-index: 1;
}

// .rbc-selected {
//   border: inherit !important;
//   background: inherit !important;
// }
