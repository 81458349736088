.branding {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 600;
    font-size: 24px;

    margin-left: 10px;
  }

  svg {
    height: 53px;
    width: 53px;
  }
}
