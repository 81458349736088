@import '/src/styles/globals.scss';

.cardHeader {
  padding: 0 85px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.cardInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    font-size: 26px;
    font-weight: 600;
  }
}

.requisitionDetails {
  display: flex;
  align-items: center;
  gap: 35px;

  p {
    font-size: 14px;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: $placeholderTextColor;
    }
  }
}

.actionButtons {
  position: relative;

  display: flex;
  align-items: center;
  gap: 12px;
}

.actionMenu {
  position: absolute;

  width: 180px;

  padding: 6px 0;

  right: 0px;
  top: calc(100% + 6px);

  z-index: 999;
  background-color: $white-color;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  ul {
    padding: 0;

    li {
      font-size: 14px;
      font-weight: 400;
      width: 100%;

      padding: 11px 25px;

      &:hover {
        cursor: pointer;
        background-color: $grayColor;
      }
    }
  }
}

.actionButton {
  width: 40px;
  height: 40px;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    transform: rotate(-90deg);
  }
}

.cardTitle {
  display: flex;
  align-items: center;
  gap: 20px;
}
