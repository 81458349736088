@import '/src/styles/globals.scss';

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;
}

.question {
  width: 310px;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  text-align: center;
}

.modalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  width: 310px;
  text-align: center;

  h3 {
    font-size: 24px;
    font-weight: 600;
  }
}

.thumbnail {
  width: 89px;
  height: 89px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid $darkPurpleBorderColor;
  border-radius: 100px;

  img {
    width: 22px;
    height: 22px;
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.addButton {
  width: 170px;
  height: 40px;
}

.cancelButton {
  width: 130px;
  height: 40px;
}
