@import '/src/styles/globals.scss';

.modal {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.icon {
  width: 90px;
  height: 90px;

  margin: 15px 0 17px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  border: 1px solid $darkPurpleBorderColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 100px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  width: 100%;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  h2 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: $placeholderTextColor;
    width: 380px;
  }
}

.question {
  width: 430px;

  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.primaryButton {
  width: 175px;
  height: 40px;
}

.cancelButton {
  width: 115px;
  height: 40px;
}

.infoWrapper {
  width: 100%;
  padding: 32px 67px;

  background-color: $grayColor;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-size: 20px;
    font-weight: 600;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.companyName {
  font-size: 14px;
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 16px;
  }
}

.candidate {
  font-weight: 600 !important;
  font-size: 14px !important;

  align-items: flex-start !important;
  gap: 0 !important;

  p {
    margin-left: 6px;
    max-width: 315px;
  }
}

.infoItem {
  font-size: 14px;
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 14px;
    font-weight: 400;
    color: $placeholderTextColor;

    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 16px;
    }
  }
}
