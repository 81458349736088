@import '/src/styles/globals.scss';

.settingsInfo {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-bottom: 27px;
  border-bottom: 1px solid $dividerColor;
  margin-bottom: 20px;

  h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  p {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-weight: 400;
    }
  }

  .group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}
