@import '/src/styles/globals.scss';

.header {
  padding: 36px 30px 0 30px;

  display: flex;
  gap: 80px;
}

.backHeader {
  padding: 0;
  border: none;
}

.headerInfo {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.infoItem {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;

  span {
    display: flex;
    align-items: center;
    gap: 8px;

    font-weight: 400;
    color: $placeholderTextColor;
  }

  p {
    font-weight: 600;
  }
}
