@import '/src/styles/globals.scss';

.statusBean {
  width: fit-content;

  padding: 5px 12px 5px 10px;
  border-radius: 100px;

  display: flex;
  align-items: center;
  gap: 8px;

  div {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  p {
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
  }
}

.pendingStatus {
  background-color: rgba(0, 181, 255, 0.05);

  div {
    background-color: $blue-color;
  }
}

.upcomingStatus {
  background-color: rgba(0, 194, 155, 0.05);

  div {
    background-color: $green-color;
  }
}

.actionRequiredStatus {
  background-color: rgba(255, 54, 7, 0.05);

  div {
    background-color: $red-color;
  }
}

.rescheduleStatus {
  background-color: rgba(255, 126, 8, 0.05);

  div {
    background-color: $orange-color;
  }
}
