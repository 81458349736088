@import '/src/styles/globals.scss';

.datetimePicker {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 14px;
  font-weight: 400;

  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    color: $placeholderTextColor;
  }
}

.pickers {
  display: flex;
  gap: 12px;
}

.input {
  width: 100%;
  height: 40px;
  padding: 12px;

  border: 1px solid $inputBorderColor;
  border-radius: 6px;

  transition: 0.3s;

  font-weight: 400;
  font-size: 14px;
  color: $placeholderTextColor;

  &:focus {
    border: 1px solid $primaryBtnColor;
    outline: none;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: $placeholderTextColor;
  }
}

.datePickerWrapper {
  width: 290px;

  position: relative;

  img {
    position: absolute;
    right: 10px;

    top: 50%; /* position the top  edge of the element at the middle of the parent */

    transform: translateY(-50%);
  }

  input {
    @extend .input;
  }
}

.timePickerWrapper {
  @extend .datePickerWrapper;

  width: 100px;
}

.wrapper {
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 6px;
  }
}
