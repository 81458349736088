@import '/src/styles/globals.scss';

.modal {
  width: 1000px;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 90px;
  height: 90px;

  margin: 15px 0 34px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  border: 1px solid $darkPurpleBorderColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 100px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 30px;
    font-weight: 600;
  }

  p {
    font-size: 24px;
    font-weight: 400;
  }
}

.okButton {
  width: 175px;
  height: 40px;
}
