@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }

  @media screen and (max-width: 1180px) {
    width: calc(100% - 16px);

    margin: 40px 8px;
  }

  @media screen and (max-width: 900px) {
    width: 700px;

    margin: 40px auto;
  }

  @media screen and (max-width: 720px) {
    width: calc(100% - 8px);

    margin: 40px 4px;
  }
}

.pageCard {
  width: 100%;
  height: 700px;

  padding: 24px 43px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.header {
  display: flex;
  justify-content: center;

  margin-bottom: 24px;
}

.brandingLogo {
  p {
    font-size: 18px;
  }

  svg {
    width: 37px !important;
    height: 37px !important;
  }
}

.divider {
  width: 100%;
  height: 1px;

  background-color: $dividerColor;
}

.informationContainer {
  width: 100%;
  min-height: calc(100% - 70px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.information {
  max-width: 1000px;
  width: 100%;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 90px;
  height: 90px;

  margin: 15px 0 26px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  border: 1px solid $darkPurpleBorderColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 100px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;

  h1 {
    max-width: 500px;

    font-size: 36px;
    font-weight: 600;

    line-height: 42px;

    text-align: center;
  }

  p {
    max-width: 440px;

    font-size: 26px;
    font-weight: 400;
    line-height: 35px;

    text-align: center;
  }
}
