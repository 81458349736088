@import '/src/styles/globals.scss';

.pollDetailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.basicInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-size: 14px;
    font-weight: 400;
  }

  span {
    font-weight: 600;
  }
}

.votesWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;

  h3 {
    font-size: 18px;
    font-weight: 600;
  }
}

.votes {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.respondentWrapper {
  padding: 16px;
  width: fit-content;

  display: flex;
  align-items: flex-start;
  gap: 10px;

  background-color: $grayColor;
  border: 1px solid $lightPurpleBackgroundColor;
  border-radius: 10px;

  img {
    width: 13px;
  }
}

.respondentVoted {
  border: 1px solid $primaryBtnColor;
}

.respondent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.initials {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $pendingColor;
  border-radius: 100px;

  font-size: 12px;
  font-weight: 600;
  color: $white-color;
}

.respondentDetails {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    color: $placeholderTextColor;
  }
}

.commentsWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;

  h3 {
    font-size: 18px;
    font-weight: 600;
  }
}

.comments {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.commentWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 24px 26px;

  background-color: $greyBackgroundColor;
  border: 1px solid $dividerColor;
  border-radius: 6px;

  p {
    font-size: 14px;
    font-weight: 600;
  }

  span {
    font-size: 14px;
    font-weight: 400;
  }
}

.bestTermsWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;

  h3 {
    font-size: 18px;
    font-weight: 600;
  }
}

.bestTerms {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.backButtonWrapper {
  display: flex;
  justify-content: center;
}

.backButton {
  width: 115px;
  height: 40px;
}
