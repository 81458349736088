@import '/src/styles/globals.scss';

.thumbnail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  cursor: pointer;
}

.initials {
  width: 32px;
  height: 32px;

  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffb300;

  font-size: 12px;
  font-weight: 600;
  color: $white-color;
}

.name {
  font-size: 14px;
  font-weight: 600;
}

.email {
  font-size: 11px;
  font-weight: 400;
  color: $placeholderTextColor;
}
