@import '/src/styles/globals.scss';

.backHeader {
  padding: 42px 30px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  border-bottom: 1px solid $dividerColor;
}

.content {
  display: flex;
  align-items: center;
  gap: 20px;

  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  &:hover {
    cursor: pointer;

    h1 {
      text-decoration: underline;
    }

    div {
      cursor: pointer;
      border-color: $primaryBtnColor;
    }
  }
}

.leftArrow {
  width: 36px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid $inputBorderColor;
  border-radius: 100px;

  transition: border-color 0.2s linear;

  img {
    transform: rotateZ(90deg);
  }
}
