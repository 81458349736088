@import '/src/styles/globals.scss';

.cardHeader {
  padding: 0 85px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    font-size: 26px;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.requisitionDetails {
  display: flex;
  align-items: center;
  gap: 35px;

  p {
    font-size: 14px;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: $placeholderTextColor;
    }
  }
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: 12px;
}

.historyButton {
  width: 100px;
  height: 40px;
}

.expandButton {
  width: 40px;
  height: 40px;

  p {
    display: flex;
    justify-content: center;
  }
}

.arrowUp {
  img {
    transform: rotate(180deg) !important;
  }
}
