@import '/src/styles/globals.scss';

.recruitmentCard {
  width: 100%;

  padding: 28px 0;

  display: flex;
  flex-direction: column;
  gap: 18px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.meetingDetails {
  margin: 6px 85px 0 62px;
  padding: 24px;

  display: flex;
  align-items: center;
  gap: 40px;

  background-color: $grayColor;
  border-radius: 10px;
}

.link {
  display: flex;
  align-items: center;
  gap: 18px;
}

.copyButton {
  width: 32px;
  height: 32px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.infoItem {
  min-width: 245px;

  display: flex;
  align-items: center;
  gap: 5px;

  font-size: 14px;
  font-weight: 400;
}

.bold {
  font-weight: 600;
}

.label {
  font-weight: 400;
  display: flex;
  gap: 10px;
  color: $placeholderTextColor;
}

.additionalInfo {
  margin: 0 86px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.meetingStatus {
  font-size: 14px;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;

  span {
    font-size: 12px;
    color: $placeholderTextColor;
  }
}

.divider {
  width: calc(100% - 170px);
  height: 1px;

  margin: 12px 85px;

  background-color: $dividerColor;
}
