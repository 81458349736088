@import '/src/styles/globals.scss';

.calendarNavigation {
  width: 100%;
  padding: 20px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;

  background-color: $grayColor;
  border-top: 1px solid $dividerColor;
  border-bottom: 1px solid $dividerColor;
}

.navText {
  font-size: 18px;
  font-weight: 600;
}

.button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.leftButton {
  @extend .button;
  transform: rotate(90deg);
}

.rightButton {
  @extend .button;
  transform: rotate(-90deg);
}

.disabledButton {
  opacity: 0;

  &:hover {
    cursor: auto;
  }
}

.daysContainer {
  width: 100%;
  padding-left: 100px;

  display: grid;
  grid-template-columns: repeat(7, 1fr);

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid $dividerColor;
  border-left: none;
  border-right: none;
}

.calendarDayHeader {
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  border-left: 1px solid $dividerColor;
}

.dayWrapper {
  padding: 4px 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  span {
    font-size: 16px;
    font-weight: 600;
  }

  .day {
    font-weight: 400;
    color: $placeholderTextColor;
  }
}

.activeDay {
  border: 1px solid $primaryBtnColor;
  border-radius: 100px;

  span {
    color: $primaryBtnColor !important;
  }
}

.disabledDay {
  background-color: $disabledGrayColor;
}

.clearTimes {
  font-size: 12px;
  font-weight: 400;
  color: $primaryBtnColor;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.pastDate {
  background-color: $disabledGrayColor;
  border: 1px solid white !important;
}

.externalEvent {
  background-color: $dividerColor !important;
  border: 1px solid $placeholderTextColor !important;
  z-index: 0 !important;
  cursor: auto !important;
}
