@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }

  @media screen and (max-width: 1180px) {
    width: calc(100% - 16px);

    margin: 40px 8px;
  }

  @media screen and (max-width: 900px) {
    width: 700px;

    margin: 40px auto;
  }

  @media screen and (max-width: 720px) {
    width: calc(100% - 8px);

    margin: 40px 4px;
  }
}

.clientPollCard {
  display: grid;
  grid-template-columns: 33% 67%;
  grid-template-areas: 'asideContent mainContent';

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}

.asideContent {
  grid-area: asideContent;

  padding: 43px 45px 43px 80px;

  display: flex;
  flex-direction: column;
  gap: 26px;

  border-right: 1px solid $dividerColor;

  @media screen and (max-width: 1200px) {
    padding: 43px 15px 43px 30px;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    border: none;
    border-bottom: 1px solid $dividerColor;
  }
}

.mainContent {
  grid-area: mainContent;

  display: flex;
  flex-direction: column;
  gap: 34px;
}

.brandingLogo {
  justify-content: flex-start;
  margin-bottom: 20px;

  p {
    font-size: 16px;
  }

  svg {
    width: 37px;
    height: 37px;
  }
}

.title {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-size: 22px;
    font-weight: 600;

    overflow-wrap: break-word;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: $placeholderTextColor;
  }
}

.requisitionDetailsList {
  padding: 0;
  margin: 20px 0 0;

  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 9px;

    overflow-wrap: break-word;

    font-size: 14px;
    font-weight: 400;

    img {
      width: 20px;
      object-fit: none;
    }
  }
}

.bold {
  font-weight: 600;
}

.label {
  color: $placeholderTextColor;
}

.divider {
  width: 100%;
  height: 1px;

  background-color: $dividerColor;
}

.noteLabel {
  display: flex;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;
  color: $placeholderTextColor;
}

.note {
  margin-top: 12px;
  margin-left: 30px;
  height: 75px;

  position: relative;

  p.shortenNote {
    position: relative;
    padding: 10px 16px 0 16px;

    font-size: 14px;
    font-weight: 400;

    text-overflow: ellipsis;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.fullNote {
  position: absolute;
  top: 0;
  left: -30px;

  z-index: 999;
  padding: 10px 15px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0px 10px 40px rgb(0 0 0 / 10%);
  width: 300px;

  p {
    font-size: 14px;
    font-weight: 400;

    overflow-wrap: break-word;
  }
}

.cardHeader {
  padding: 43px 100px 28px 45px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  border-bottom: 1px solid $dividerColor;

  @media screen and (max-width: 700px) {
    padding: 43px 20px 34px 20px;
  }
}

.meetingPollLabel {
  font-size: 14px;
  font-weight: 400;
  color: $placeholderTextColor;
}

.titleHeader {
  display: flex;
  flex-direction: column;
  gap: 5px;

  h1 {
    font-size: 24px;
    font-weight: 600;
  }

  .headerInfo {
    font-size: 14px;
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }
}

.timezone {
  font-size: 14px;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  gap: 3px;
}
