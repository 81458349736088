@import '/src/styles/globals.scss';

.requisitionCard {
  width: 100%;

  padding: 28px 0;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.divider {
  width: calc(100% - 170px);
  height: 1px;

  margin: 24px 85px 0;

  background-color: $dividerColor;
}
