@import '/src/styles/globals.scss';

.infoCard {
  width: 100%;

  padding: 42px 85px 0;

  display: flex;
  flex-direction: column;
  gap: 40px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  width: fit-content;

  display: flex;
  align-items: center;
  gap: 20px;

  font-size: 32px;
  font-weight: 600;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: $textColor;
}

.tabs {
  list-style: none;

  display: flex;
  gap: 29px;

  padding: 0;
  margin: 0;

  overflow: hidden;

  li {
    height: 100%;

    position: relative;

    font-size: 14px;
    font-weight: 400;

    padding: 0 7px 14px;
    cursor: pointer;

    &::after {
      display: block;
      position: absolute;
      bottom: -2px;
      content: '';
      width: 0;
      height: 4px;
      background-color: $primaryBtnColor;
      border-radius: 4px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.2s linear;
    }

    &:hover::after {
      width: 100%;
    }
  }

  .activeTab {
    font-weight: 600;

    &::after {
      display: block;
      position: absolute;
      bottom: -2px;
      content: '';
      width: 100%;
      height: 4px;
      background-color: $primaryBtnColor;
      border-radius: 4px;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.2s linear;
    }
  }
}

.secondRow {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.bullhornSync {
  position: absolute;
  top: -5px;
  right: 0;

  display: flex;
  align-items: center;
  gap: 16px;

  .syncInfo {
    font-size: 12px;
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }
}

.syncButton {
  height: 28px;
}

.refreshButtonCTA {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  font-size: 12px;
}

.actionButton {
  padding: 12px 16px;
}

.aiMatchingSection {
  display: flex;
  align-items: center;
  gap: 24px;
}
