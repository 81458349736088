@import '/src/styles/globals.scss';

.participantSectionContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.participantSectionHeader {
  display: flex;
  align-items: center;
  gap: 18px;

  h3 {
    font-size: 18px;
    font-weight: 600;
  }
}

.punction {
  width: 28px;
  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $lightPurpleBackgroundColor;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border: 1px solid $darkPurpleBorderColor;
  border-radius: 6px;

  font-size: 14px;
  font-weight: 600;
  color: $primaryBtnColor;
}

.participants {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.skipParticipants {
  padding: 15px 20px;
  margin-left: 46px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $grayColor;
  border-radius: 10px;
}

.info {
  font-size: 14px;
  font-weight: 400;
}

.bold {
  font-weight: 600;
}

.switch {
  position: relative;
  width: 45px;
  height: 25px;

  cursor: pointer;
}

.switch::before {
  content: '';
  position: absolute;
  width: 45px;
  height: 25px;
  background-color: $infoColor;
  border-radius: 100vh;

  transition: all 0.2s linear;
}

.switch::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  background-color: $white-color;
  border-radius: 50%;
  margin: 2px;
}

.switch:checked::before {
  background-color: $primaryBtnColor;
}
.switch:checked::after {
  transform: translateX(20px);
}
