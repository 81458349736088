@import '/src/styles/globals.scss';

.meetingFormContainer {
  display: flex;
  flex-direction: column;
  gap: 37px;
}

.meetingDate {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;

  display: flex;
  align-items: center;
  gap: 12px;

  img {
    width: 16px;
  }
}

.meetingDateInfo {
  margin-left: 29px;
  font-size: 12px;
  font-weight: 400;
  color: $placeholderTextColor;
}

.dateForm {
  margin-top: 10px;
  margin-left: 29px;
}

.candidate {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.candidateTile {
  margin-left: 29px;
  padding: 16px;

  display: flex;
  align-items: center;
  gap: 8px;

  background-color: $grayColor;
  border-radius: 10px;
}

.initials {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $pendingColor;
  border-radius: 100px;

  font-size: 12px;
  font-weight: 600;
  color: $white-color;
}

.candidateDetails {
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    color: $placeholderTextColor;
  }
}

.participants {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.participantsForm {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.participantListItem {
  margin-left: 29px;
}

.meetingLink {
  display: flex;
  flex-direction: column;
  gap: 18px;

  input {
    margin-left: 29px;

    width: 100%;
    height: 40px;

    padding: 12px;
    padding-right: 40px;

    border: 1px solid $inputBorderColor;
    border-radius: 6px;

    font-weight: 400;
    font-size: 14px;

    transition: 0.3s;

    &:focus {
      border: 1px solid $primaryBtnColor;
      outline: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $placeholderTextColor;
    }
  }
}

.actionButtons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.addButton {
  width: 160px;
  height: 40px;
}

.cancelButton {
  width: 120px;
  height: 40px;
}
