@import '/src/styles/globals.scss';

.candidatesLabel {
  display: flex;
  align-items: center;
  gap: 9px;

  font-size: 14px;
  font-weight: 400;

  img {
    width: 20px;
    object-fit: none;
  }

  span {
    color: $placeholderTextColor;
  }
}

.candidatesList {
  margin: 22px 0 0;
  padding-left: 29px;

  list-style: none;

  li {
    padding: 18px 0;
    border-bottom: 1px solid $dividerColor;
  }

  li:first-child {
    padding-top: 0;
  }

  li:last-child {
    border-bottom: none;
  }
}

.note {
  margin-top: 12px;
  height: 75px;

  position: relative;

  p.shortenNote {
    position: relative;
    padding: 10px 16px 0 16px;

    font-size: 14px;
    font-weight: 400;

    text-overflow: ellipsis;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.fullNote {
  position: absolute;
  top: 0;
  left: -30px;

  z-index: 999;
  padding: 10px 15px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0px 10px 40px rgb(0 0 0 / 10%);
  width: 300px;

  p {
    font-size: 14px;
    font-weight: 400;

    overflow-wrap: break-word;
  }
}
