@import '/src/styles/globals.scss';

.candidatesNames {
  padding: 24px 85px 0;

  display: flex;
  gap: 12px;
}

.candidatesLabel {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 14px;
    font-weight: 400;
    color: $placeholderTextColor;
  }
}

.candidatesNamesList {
  padding: 0;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;

  list-style: none;

  font-size: 16px;
  font-weight: 600;
}
