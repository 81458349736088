@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }
}

.pollSummaryCard {
  display: grid;
  grid-template-columns: 33% 67%;
  grid-template-areas:
    'backHeader backHeader'
    'aside pollSummary';

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.backHeader {
  grid-area: backHeader;
}

.requisitionInfo {
  grid-area: aside;

  padding: 43px 45px 43px 80px;

  border-right: 1px solid $dividerColor;
}

.pollSummary {
  grid-area: pollSummary;

  padding: 43px 100px 43px 45px;

  display: flex;
  flex-direction: column;
  gap: 34px;
}

.divider {
  width: 100%;
  height: 1px;

  margin: 28px 0;

  background-color: $dividerColor;
}

.comments {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .label {
    display: flex;
    align-items: center;
    gap: 14px;

    p {
      font-size: 18px;
      font-weight: 600;
    }

    img {
      width: 16px;
    }
  }

  .textarea {
    p {
      font-size: 12px;
      color: $placeholderTextColor;
      width: 640px;
      text-align: right;
    }

    .charactersLimit {
      color: $red-color;
    }
  }

  textarea {
    resize: none;
    width: 640px;
    height: 70px;

    padding: 12px;

    font-size: 14px;

    border: 1px solid $inputBorderColor;
    border-radius: 6px;

    &:focus {
      border: 1px solid $primaryBtnColor;
      outline: none;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      color: $placeholderTextColor;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.backButton {
  width: 115px;
}

.publishMeetingButton {
  width: 215px;
}

.pollSummaryModal {
  width: 1000px;
  height: 600px;
}
