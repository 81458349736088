@import '/src/styles/globals.scss';

.container {
  width: 1145px;

  margin: 40px auto;

  display: flex;
  flex-direction: column;
  gap: 8px;

  text-align: center;

  @media screen and (min-width: 1300px) {
    width: 1245px;
  }

  @media screen and (max-width: 1180px) {
    width: calc(100% - 16px);

    margin: 40px 8px;
  }

  @media screen and (max-width: 900px) {
    width: 700px;

    margin: 40px auto;
  }

  @media screen and (max-width: 720px) {
    width: calc(100% - 8px);

    margin: 40px 4px;
  }
}

.pageCard {
  width: 100%;

  padding: 24px 43px;

  background-color: $white-color;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.04);
  border-radius: 25px;
}

.header {
  display: flex;
  justify-content: center;

  margin-bottom: 24px;
}

.brandingLogo {
  p {
    font-size: 18px;
  }

  svg {
    width: 37px !important;
    height: 37px !important;
  }
}

.divider {
  width: 100%;
  height: 1px;

  background-color: $dividerColor;
}

.content {
  padding: 50px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  div {
    h1 {
      font-size: 44px;
      font-weight: 600;
      line-height: 52px;
    }

    p {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
    }

    @media screen and (max-width: 900px) {
      h1 {
        font-size: 24px;
        line-height: 30px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .button {
    width: 290px;
  }
}
