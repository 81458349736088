@import '/src/styles/globals.scss';

.candidateRow {
  display: grid;
  grid-template-columns: 0.4fr 1.4fr 0.8fr 1.8fr 1fr 40px;
  align-items: center;
}

.candidateType {
  font-size: 14px;
  font-weight: 400;
}

.existingNote {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 1300px) {
    max-width: 280px;
  }

  .noteParagraph {
    position: relative;

    font-size: 14px;
    font-weight: 400;

    text-overflow: ellipsis;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .expandedNote {
    cursor: pointer;
  }

  .fullNote {
    position: absolute;
    top: 0;
    left: -50px;

    z-index: 999;
    padding: 10px 15px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0px 10px 40px rgb(0 0 0 / 10%);
    width: 280px;

    p {
      max-width: 100%;

      font-size: 14px;
      font-weight: 400;

      overflow-wrap: break-word;
    }
  }
}

.note {
  max-width: 310px;

  p {
    max-width: 200px;
    overflow-wrap: break-word;
  }
}

.noteButtons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.noteButton {
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.addNewNote {
  width: fit-content;

  display: flex;
  align-items: center;
  gap: 12px;

  p {
    font-size: 14px;
    font-weight: 600;
  }

  .addNote {
    width: 36px;
    height: 36px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid $inputBorderColor;
    border-radius: 100px;

    transition: border-color 0.2s linear;
  }

  &:hover {
    cursor: pointer;

    .addNote {
      border-color: $primaryBtnColor;
    }

    p {
      text-decoration: underline;
    }
  }
}

.action {
  position: relative;

  width: fit-content;
}

.actionButton {
  width: 40px;
  height: 40px;

  p {
    display: flex;
    justify-content: center;
  }
}

.actionsDropdown {
  width: 150px;

  padding: 6px 0;

  position: absolute;
  right: 0px;
  bottom: -105px;

  z-index: 999;
  background-color: $white-color;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  ul {
    padding: 0;

    li {
      font-size: 14px;
      font-weight: 400;
      width: 100%;

      padding: 11px 25px;

      &:hover {
        cursor: pointer;
        background-color: $grayColor;
      }
    }
  }
}

.actionsDropdownShorter {
  bottom: -62px;
}
